@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-image: url('https://mmc.tirto.id/image/2018/11/20/sejarah-nama-intelkam--ilustrasi--nadya-02.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }
  
  #root {
    position: relative;
  }


  @media screen and (min-width: 1024px){
    .container{
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    
    .card{
        width: 40%;
        height: auto;
        padding: 20px;
    }
    
    .form{
        display: flex;
        flex-direction: column;
    }
    
    .logo{
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }
    
    .logo img{
        width: 100%;
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 481px) and (max-width: 1024px){
    .container{
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    
    .card{
        width: 60%;
        height: auto;
        padding: 20px;
    }
    
    .form{
        display: flex;
        flex-direction: column;
    }
    
    .logo{
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }
    
    .logo img{
        width: 50%;
        margin-bottom: 20px;
    }
}

@media (max-width: 480px){
    .container{
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    
    .card{
        width: 100%;
        height: auto;
        padding: 20px;
    }
    
    .form{
        display: flex;
        flex-direction: column;
    }
    
    .logo{
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }
    
    .logo img{
        width: 50%;
        margin-bottom: 20px;
    }
}
/* Google Font Link */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
.sidebar {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    background: #11101d;
    padding: 6px 14px;
    z-index: 99;
    transition: all 0.5s ease;
}
.sidebar.open {
    width: 100%;
}
.sidebar .logo-details {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
}
.sidebar .logo-details .icon {
    opacity: 0;
    transition: all 0.5s ease;
}
.sidebar .logo-details .logo_name {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    opacity: 0;
    transition: all 0.5s ease;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
    opacity: 1;
}
.sidebar .logo-details #btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 22px;
    transition: all 0.4s ease;
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn {
    text-align: right;
}
.sidebar i {
    color: #fff;
    height: 60px;
    min-width: 50px;
    font-size: 28px;
    text-align: center;
    line-height: 60px;
}
.sidebar .nav-list {
    /* margin-top: 20px; */
    height: 100%;
    display: flex;
}
.sidebar li {
    position: relative;
    margin: 8px 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 33%;
}
.sidebar li .tooltip {
    position: absolute;
    top: -20px;
    left: calc(100% + 15px);
    z-index: 3;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    opacity: 0;
    pointer-events: none;
    transition: 0s;
}
.sidebar li:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
}
.sidebar.open li .tooltip {
    display: none;
}
.sidebar input {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    outline: none;
    height: 50px;
    width: 100%;
    width: 50px;
    border: none;
    border-radius: 12px;
    transition: all 0.5s ease;
    background: #1d1b31;
}
.sidebar.open input {
    padding: 0 20px 0 50px;
    width: 100%;
}
.sidebar .bx-search {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 22px;
    background: #1d1b31;
    color: #fff;
}
.sidebar.open .bx-search:hover {
    background: #1d1b31;
    color: #fff;
}
.sidebar .bx-search:hover {
    background: #fff;
    color: #11101d;
}
.sidebar li a {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
    background: #11101d;
    flex-direction: column;
}
.sidebar li a:hover {
    background: #fff;
}
.sidebar li a .links_name {
    color: #fff;
    font-size: 0.7rem;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
}
.sidebar.open li a .links_name {
    opacity: 1;
    pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i {
    transition: all 0.5s ease;
    color: #11101d;
}
.sidebar li i {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    border-radius: 12px;
}
.sidebar li.profile {
    position: fixed;
    height: 60px;
    width: 78px;
    left: 0;
    bottom: -8px;
    padding: 10px 14px;
    background: #1d1b31;
    transition: all 0.5s ease;
    overflow: hidden;
}
.sidebar.open li.profile {
    width: 250px;
}
.sidebar li .profile-details {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
.sidebar li img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 6px;
    margin-right: 10px;
}
.sidebar li.profile .name,
.sidebar li.profile .job {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    white-space: nowrap;
}
.sidebar li.profile .job {
    font-size: 12px;
}
.sidebar .profile #log_out {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: #1d1b31;
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-radius: 0px;
    transition: all 0.5s ease;
}
.sidebar.open .profile #log_out {
    width: 50px;
    background: none;
}
.home-section {
    position: relative;
    background: #e4e9f7;
    top: 0;
    width: 100%;
    transition: all 0.5s ease;
    z-index: 2;
    padding: 25px;
    min-height: 100vh;
}
.sidebar.open ~ .home-section {
    width: 100%;
}
.home-section .text {
    display: inline-block;
    color: #11101d;
    font-size: 25px;
    font-weight: 500;
    margin: 18px;
}

.main-content {
    background-color: #fff;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}



/* Table */
#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04aa6d;
    color: white;
}


/* Table2 */
#customers2 {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers2 td,
#customers2 th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers2 tr {
    background-color: #fff
}

#customers2 tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers2 tr:hover {
    background-color: #ddd;
}

#customers2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04aa6d;
    color: white;
}

.h1-main{
    font-size: 3rem;
    font-weight: bold;
}

.h1-sub{
    font-size: 1.8rem;
    font-weight: bold;
}


.header{
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #ddd;
}

.header p{
    font-size: 1.2rem;
}

.cards{
    border: 0px;
   
    color: #fff;
}

.card h1{
    font-size: 3rem;
}

.flex-row{
    display: flex;
    flex-direction: row;
}

.flex-col{
    display: flex;
    flex-direction: column;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}



.w-30 {
    width: 30%;
}


.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-100 {
    width: 100%;
}

.p-20{
    padding: 20px;
}

.pr-10{
    padding-right: 20px;
}

.mb-20{
    margin-bottom: 20px;
}

.bg-green{
    background-color: #04aa6d;
}

.bg-blue{
    background-color: #0984e3;
}

.bg-pink{
    background-color: #f368e0;
}

.bg-imperial{
    background-color: #222f3e;
}

.border-grey{
    border: 1px solid #ddd
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
    
}


.mdb-dataTable-head{
    background-color: #04aa6d !important;
    color: #fff;
}

.mdb-datatable-filter{
    margin-bottom: 20px;
}
